import { Button } from 'former-kit'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import OnboardingBackground from '../../components/OnboardingBackground'
import Spinner from '../../components/Spinner'
import ArrowBack from './arrow-back.svg'
import CardOptions from './CardOptions'
import DropdownOptions from './DropownOptions'
import ProgressBar from './ProgressBar'
import RadioCardOptions from './RadioCardOptions'
import SegmentOptions from './SegmentOptions'
import styles from './styles.css'
import Welcome from './Welcome'
import OnboardingEvents from '../../amplitude-events/Onboarding.amplitude'

const OnboardingContainer = ({
  error,
  handleStartOnboarding,
  loading,
  onReturn,
  onSubmit,
  onboardingStarted,
  progressPercent,
  question,
  questionSettings,
  status,
  t,
}) => {
  useEffect(() => {
    if (!question?.label) return
    OnboardingEvents.events.view.loadView(question.label)
  }, [question])

  const handleSubmit = (answer, macroSegmentAnswer = undefined) => {
    const answerLabel = macroSegmentAnswer
      || question.options.find(option => option.value === answer)?.label
    OnboardingEvents.events.actions.answerQuestionsButtonClicked({
      answerLabel,
      questionLabelKey: question.label,
    })
    onSubmit(answer)
  }

  if (loading) {
    return (
      <OnboardingBackground>
        <div className={styles.loading}>
          <Spinner />
        </div>
      </OnboardingBackground>
    )
  }

  if (error) {
    return (
      <OnboardingBackground>
        <div className={styles.error}>
          <span>{t('pages.onboarding.error.title')}</span>
          <Button
            onClick={() => window.location.reload(true)}
            size="huge"
            fullWidth
          >
            {t('pages.onboarding.error.refreshPage')}
          </Button>
        </div>
      </OnboardingBackground>
    )
  }

  if (!onboardingStarted) {
    return (
      <OnboardingBackground>
        <Welcome
          t={t}
          handleStartOnboarding={handleStartOnboarding}
        />
      </OnboardingBackground>
    )
  }

  return (
    <OnboardingBackground>
      <div className={styles.onboardingQuestions}>
        <div>
          <Button fill="clean" icon={<ArrowBack />} onClick={onReturn} />
          <h1 className={styles.title}>{question?.title}</h1>
          {question?.subtitle && <h3>{question?.subtitle}</h3>}
          {
            questionSettings.type === 'card' && (
              <CardOptions
                handleSubmit={handleSubmit}
                images={questionSettings?.images}
                options={question?.options}
              />
            )
          }
          {
            questionSettings.type === 'radio-card' && (
              <RadioCardOptions
                handleSubmit={handleSubmit}
                options={question?.options}
                t={t}
              />
            )
          }
          {
            questionSettings.type === 'drop-down' && (
              <DropdownOptions
                handleSubmit={handleSubmit}
                isLastQuestion={status === 'finishing'}
                options={question?.options}
                placeholderPath={questionSettings?.placeholder}
                t={t}
              />
            )
          }
          {
            questionSettings.type === 'segments' && (
              <SegmentOptions
                handleSubmit={handleSubmit}
                images={questionSettings.images}
                options={question?.options}
                notFoundText={questionSettings.notFoundText}
                t={t}
              />
            )
          }
        </div>
        <ProgressBar
          progressPercent={progressPercent}
        />
      </div>
    </OnboardingBackground>
  )
}

OnboardingContainer.propTypes = {
  error: PropTypes.bool,
  handleStartOnboarding: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  onboardingStarted: PropTypes.bool.isRequired,
  onReturn: PropTypes.func,
  onSubmit: PropTypes.func,
  progressPercent: PropTypes.number,
  question: PropTypes.shape({
    label: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string,
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    subtitle: PropTypes.string,
    title: PropTypes.string,

  }),
  questionSettings: PropTypes.shape({
    deadEnd: PropTypes.func,
    images: PropTypes.arrayOf(PropTypes.func),
    notFoundText: PropTypes.string,
    placeholder: PropTypes.string,
    progressPercent: PropTypes.number,
    type: PropTypes.oneOf(['card', 'radio-card', 'drop-down', 'segments']),
  }),
  status: PropTypes.oneOf(['starting', 'boarding', 'finishing']).isRequired,
  t: PropTypes.func.isRequired,
}

OnboardingContainer.defaultProps = {
  error: false,
  handleStartOnboarding: () => { },
  onReturn: () => { },
  onSubmit: () => { },
  progressPercent: 0,
  question: {},
  questionSettings: {},
}

export default OnboardingContainer
