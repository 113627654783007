
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { getAnaliticaInstance, setupAnalitica } from '@pagarme/plugins'
import { amplitudeKey } from '../environment'
import analiticaInstance from '../plugins/analitica.config'

const OnboardingProvider = ({ children }) => {
  const {
    company,
    user,
  } = useSelector(state => state.account)
  const analiticaInstancePlugin = getAnaliticaInstance()

  const [setupedAnalitica, setSetupedAnalitica] = useState(false)

  if (!setupedAnalitica) {
    const isProduction = process.env.NODE_ENV === 'production'

    setupAnalitica({
      apiKey: amplitudeKey,
      dryRun: isProduction,
      projectName: 'Pilot',
    })

    setSetupedAnalitica(true)
  }

  useEffect(() => {
    analiticaInstance.updateMetadata({
      context: 'form qualificação',
      url: window.location.href,
      user: {
        account_id: user.id,
        merchant_id: company.id,
        user_email: user.email,
      },
    })
  }, [company, analiticaInstancePlugin, setupedAnalitica, user.id, user.email])

  return <>{children}</>
}

OnboardingProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default OnboardingProvider
