import axios from "axios";
import { clone } from "ramda";
import { baseUrl } from "../index";
import { formatISO, subWeeks } from "date-fns";
import { removeUTCMinusThree, TWO_YEARS_IN_WEEKS } from "../../index";
function prepareUrl(options) {
  let url = baseUrl;
  url = url.replace(":company_id", options.companyId);
  return url;
}
export default (axiosConfig, options) => {
  return params => {
    const config = clone(axiosConfig);
    config.url = prepareUrl(options);
    config.params.ids = params.ids;
    config.params.createdSince = removeUTCMinusThree(formatISO(subWeeks(new Date(), TWO_YEARS_IN_WEEKS)));
    config.params.createdUntil = removeUTCMinusThree(formatISO(new Date()));
    config.method = "GET";
    return axios(config);
  };
};