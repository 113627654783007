import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Route,
  Switch,
} from 'react-router-dom'
import useGetCompanyKeys from './CompanySettings/pages/ApiData/hooks/useGetCompanyKeys'
import CheckApiOldKeys from './CompanySettings/pages/CheckApiOldKeys'
import companyIdsJson from './CompanySettings/pages/CheckApiOldKeys/companyIdBypass.json'
import KycPage from './Kyc'
import { useKyc } from './Kyc/context/KycContext'
import Conclusion from './Kyc/pages/Conclusion'
import LoggedArea from './LoggedArea'
import OnboardingProvider from '../providers/Onboarding'
import Onboarding from './Onboarding/Onboarding'

const PagesGuard = () => {
  const { skipOnboarding, status } = useKyc()
  const kycIsApproved = status === 'approved'
  const { company } = useSelector(state => state.account)
  const { data, refetch } = useGetCompanyKeys({ page: 1, size: 10 })

  const isKeyOutdated = (createdAt, limitDate) => new Date(
    createdAt
  ) <= limitDate

  const isCompanyIdInList = id => companyIdsJson.company_ids.includes(id)

  const [outdatedKeys, setOutdatedKeys] = useState([])
  const [keys, setKeys] = useState([])
  const [isKeysLoaded, setIsKeysLoaded] = useState(false)
  const limitDate = new Date('2023-12-14T23:59:59.999Z')

  const fetchKeys = () => {
    if (data) {
      const fetchedKeys = data.items || []
      setKeys(fetchedKeys)
      setIsKeysLoaded(true)
    }
  }

  const handleDeleteKey = async (keyId) => {
    setOutdatedKeys(
      prevOutdatedKeys => prevOutdatedKeys.filter(key => key.id !== keyId)
    )
    refetch()
  }

  useEffect(() => {
    fetchKeys()
  }, [data])

  useEffect(() => {
    if (kycIsApproved && skipOnboarding
      && isKeysLoaded && isCompanyIdInList(company.id)) {
      const outdated = keys.filter(
        key => isKeyOutdated(key.created_at, limitDate)
      )
      setOutdatedKeys(outdated)
    }
  }, [keys, kycIsApproved, skipOnboarding, isKeysLoaded])

  if (!kycIsApproved || !skipOnboarding) {
    return (
      <Switch>
        {kycIsApproved && (
          <OnboardingProvider>
            <Route
              component={Onboarding}
              exact
              path="/onboarding"
            />
          </OnboardingProvider>
        )}
        <Route
          component={KycPage}
          path="*"
        />
      </Switch>
    )
  }

  if (outdatedKeys.length > 0) {
    return (
      <Switch>
        <Route
          exact
          path="*"
          render={() => (
            <CheckApiOldKeys
              outdatedKeys={outdatedKeys}
              onDeleteKey={handleDeleteKey}
            />
          )}
        />
      </Switch>
    )
  }

  return (
    <Switch>
      <Route
        component={Conclusion}
        exact
        path="/kyc-conclusion"
      />
      <Route
        component={LoggedArea}
        path="*"
      />
    </Switch>
  )
}

export default PagesGuard
