import axios from "axios";
import { clone } from "ramda";
import { baseUrl } from "../index";
import { endOfDay, formatISO, startOfDay, subWeeks } from "date-fns";
import { removeUTCMinusThree, TWO_YEARS_IN_WEEKS } from "../../index";
function prepareUrl(options) {
  let url = baseUrl;
  url = url.replace(":company_id", options.companyId);
  return url;
}
export function setParams(params, axiosConfig) {
  var _a, _b;
  if ((_a = params === null || params === void 0 ? void 0 : params.dates) === null || _a === void 0 ? void 0 : _a.start) {
    axiosConfig.params.createdSince = removeUTCMinusThree(formatISO(startOfDay(new Date(params.dates.start))));
  } else {
    axiosConfig.params.createdSince = removeUTCMinusThree(formatISO(startOfDay(subWeeks(new Date(), TWO_YEARS_IN_WEEKS))));
  }
  if ((_b = params === null || params === void 0 ? void 0 : params.dates) === null || _b === void 0 ? void 0 : _b.end) {
    axiosConfig.params.createdUntil = removeUTCMinusThree(formatISO(endOfDay(new Date(params.dates.end))));
  } else {
    axiosConfig.params.createdUntil = removeUTCMinusThree(formatISO(endOfDay(new Date())));
  }
  if (params.cursor) axiosConfig.params.cursor = params.cursor;
  if (params.dateFilters) axiosConfig.params.dateFilters = params.dateFilters;
  if (params.ids) axiosConfig.params.ids = params.ids;
  if (params.name) axiosConfig.params.name = params.name;
  if (params.days) axiosConfig.params.days = params.days;
  if (params.trialDays) axiosConfig.params.trialDays = params.trialDays;
  if (params.amounts) axiosConfig.params.amounts = params.amounts;
  if (params.paymentMethods) axiosConfig.params.paymentMethods = params.paymentMethods;
}
export default (axiosConfig, options) => {
  return params => {
    const config = clone(axiosConfig);
    config.url = prepareUrl(options);
    setParams(params, config);
    config.method = "GET";
    return axios(config);
  };
};