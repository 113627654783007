import axios from "axios";
import { clone } from "ramda";
import { baseUrl } from "..";
function prepareUrl(options, transactionId) {
  let url = baseUrl;
  url = url.replace(":company_id", options.companyId);
  return "".concat(url, "/").concat(transactionId);
}
export default (axiosConfig, options) => {
  return params => {
    const config = clone(axiosConfig);
    config.url = prepareUrl(options, params.transactionId);
    config.method = "GET";
    return axios(config);
  };
};