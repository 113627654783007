import axios from "axios";
import { clone } from "ramda";
import { baseUrl } from "../index";
import { removeUTCMinusThree } from "../../index";
import { formatISO } from "date-fns";
function prepareUrl(options) {
  let url = baseUrl;
  url = url.replace(":company_id", options.companyId);
  return "".concat(url, "/reports");
}
function convertToBrazilTimezone(date) {
  return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
    timeZone: 'America/Sao_Paulo'
  }));
}
export function setParams(params, axiosConfig) {
  if (params === null || params === void 0 ? void 0 : params.createdSince) {
    axiosConfig.params.createdSince = removeUTCMinusThree(formatISO(new Date(params === null || params === void 0 ? void 0 : params.createdSince)));
  }
  if (params === null || params === void 0 ? void 0 : params.createdUntil) {
    axiosConfig.params.createdUntil = removeUTCMinusThree(formatISO(new Date(params.createdUntil).setHours(23, 59, 59, 999)));
  }
  if (!(params === null || params === void 0 ? void 0 : params.createdUntil) && (params === null || params === void 0 ? void 0 : params.createdSince)) {
    axiosConfig.params.createdUntil = removeUTCMinusThree(formatISO(new Date(params.createdSince).setHours(23, 59, 59, 999)));
  }
  if (params === null || params === void 0 ? void 0 : params.customerDocuments) {
    axiosConfig.params.customerDocuments = params.customerDocuments.replace(/[-./]/g, '');
  }
  if (params === null || params === void 0 ? void 0 : params.updatedCreatedSince) {
    axiosConfig.params.updatedCreatedSince = removeUTCMinusThree(formatISO(convertToBrazilTimezone(params === null || params === void 0 ? void 0 : params.updatedCreatedSince)));
  }
  if (params === null || params === void 0 ? void 0 : params.updatedCreatedUntil) {
    axiosConfig.params.updatedCreatedUntil = removeUTCMinusThree(formatISO(convertToBrazilTimezone(params.updatedCreatedUntil).setHours(23, 59, 59, 999)), true);
  }
  if (!(params === null || params === void 0 ? void 0 : params.updatedCreatedUntil) && (params === null || params === void 0 ? void 0 : params.updatedCreatedSince)) {
    axiosConfig.params.updatedCreatedUntil = removeUTCMinusThree(formatISO(convertToBrazilTimezone(params.updatedCreatedSince).setHours(23, 59, 59, 999)), true);
  }
}
export default (axiosConfig, options) => {
  return (params, exportOptions) => {
    const config = clone(axiosConfig);
    config.url = prepareUrl(options);
    config.params = Object.assign(Object.assign({}, axiosConfig.params), params);
    setParams(params, config);
    config.method = "POST";
    config.data = exportOptions;
    return axios(config);
  };
};