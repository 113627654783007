import axios from "axios";
import { clone } from "ramda";
import { baseUrl } from "../index";
function prepareUrl(options) {
  let url = "".concat(baseUrl, "/qr-code");
  url = url.replace(":company_id", options.companyId);
  return url;
}
export default (axiosConfig, options) => {
  return () => {
    const config = clone(axiosConfig);
    config.url = prepareUrl(options);
    config.method = "POST";
    return axios(config);
  };
};