import { lazy } from 'react'

const Anticipation = lazy(() => import(
  /* webpackChunkName: "transfers" */ '../Anticipation'
))

const Password = lazy(() => import(
  /* webpackChunkName: "password" */ '../Password'
))

const Home = lazy(() => import(
  /* webpackChunkName: "home" */ '../Home/Home'
))

const Balance = lazy(() => import(
  /* webpackChunkName: "balance" */ '../Balance/Balance'
))

const Transactions = lazy(() => import(
  /* webpackChunkName: "transactions" */ '../Transactions'
))

const CompanySettings = lazy(() => import(
  /* webpackChunkName: "company-settings" */ '../CompanySettings'
))

const Recipients = lazy(() => import(
  /* webpackChunkName: "recipients" */ '../Recipients'
))

const Welcome = lazy(() => import(
  /* webpackChunkName: "empty-state" */ '../Welcome/Welcome'
))

const PaymentLinks = lazy(() => import(
  /* webpackChunkName: "payment-links" */ '../PaymentLinks'
))

const Payments = lazy(() => import(
  /* webpackChunkName: "payments" */ '../Payments'
))

const Settlements = lazy(() => import(
  /* webpackChunkName: "settlements" */ '../Settlements'
))

const Plans = lazy(() => import(
  /* webpackChunkName: "plans" */ '../Plans'
))

const Subscriptions = lazy(() => import(
  /* webpackChunkName: "subscriptions" */ '../Subscriptions'
))

const Team = lazy(() => import(
  /* webpackChunkName: "team" */ '../Team'
))

const Transfers = lazy(() => import(
  /* webpackChunkName: "transfers" */ '../Transfers'
))

const EarningStatements = lazy(() => import(
  /* webpackChunkName: "earningStatements" */ '../EarningStatements'
))

const Contracts = lazy(() => import('@pagarme/contracts'))
const ReceivablesRegister = lazy(() => import('@pagarme/receivables-register'))

export {
  Anticipation,
  Balance,
  CompanySettings,
  Contracts,
  Home,
  Password,
  PaymentLinks,
  Payments,
  Plans,
  ReceivablesRegister,
  Recipients,
  Settlements,
  Subscriptions,
  Team,
  Transactions,
  Transfers,
  EarningStatements,
  Welcome,
}
