import axios from "axios";
import { clone } from "ramda";
import { baseUrl } from "..";
export default axiosConfig => {
  return params => {
    var _a;
    const config = clone(axiosConfig);
    config.url = "".concat(baseUrl, "/connect/revocation");
    config.method = "POST";
    config.headers = Object.assign(Object.assign({}, (_a = config.headers) !== null && _a !== void 0 ? _a : {}), {
      ["Content-Type"]: "application/x-www-form-urlencoded"
    });
    config.data = {
      client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
      client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET,
      token_type_hint: "refresh_token",
      token: params.refreshToken
    };
    return axios(config);
  };
};