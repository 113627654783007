import analiticaInstance from '../plugins/analitica.config'
/**
 * @typedef {import('@stone-payments/analitica').EventFactory} EventFactory
 * @description This is the event that will be sent to the Amplitude plugin
 * @description import Amplitude from '@amplitude/resume.amplitude'
 * @example Amplitude.events.actions.exportDataClicked()
 * @description
 * @example context: { filters: {}, viewed: {loadView: () => ({})}, actions: {} }
 */

const onboardingQuestionsMapper = {
  companyStatus: {
    page: 'já está vendendo em seu site',
    section_reference: 'já está vendendo em seu site',
  },
  expectedRevenue: {
    page: 'faturamento esperado',
    section_reference: 'faturamento esperado',
  },
  firstSale: {
    page: 'previsão primeira venda',
    section_reference: 'previsão primeira venda',
  },
  integrationType: {
    page: 'como quer vender com pagar.me',
    section_reference: 'como quer vender com pagar.me',
  },
  segment: {
    page: 'ramo de atuação',
    section_reference: 'ramo de atuação',
  },
  'segment-detailed': {
    page: 'ramo de atuação detalhado',
    section_reference: 'ramo de atuação detalhado',
  },
  'website-plataform': {
    page: 'plataforma do site',
    section_reference: 'plataforma do site',
  },
  'welcome-page': {
    page: 'inicial',
    section_reference: 'sua conta foi aprovada',
  },
}

const OnboardingEvents = analiticaInstance.eventFactory('OnboardingEvents', {
  actions: {
    answerQuestionsButtonClicked: ({ answerLabel, questionLabelKey = '' }) => ({
      label: 'cta clicked - pagarme dash',
      metadata: {
        ...onboardingQuestionsMapper[questionLabelKey],
        link_reference: answerLabel,
      },
    }),
  },
  view: {
    loadView: questionLabelKey => ({
      label: 'page viewed - pagarme dash',
      metadata: {
        page: onboardingQuestionsMapper[questionLabelKey].page,
      },
    }),
  },
})

export default OnboardingEvents
