
import PropTypes from 'prop-types'
import {
  compose,
  prop,
} from 'ramda'
import React, {
  useEffect,
  useState,
} from 'react'
import { translate } from 'react-i18next'
import { connect } from 'react-redux'
import { Redirect, useHistory, withRouter } from 'react-router-dom'
import FakeLoader from '../../components/FakeLoader'
import OnboardingContainer from '../../containers/Onboarding'
import getPagarmePlatforms from '../../utils/helpers/getPlatforms'
import {
  completeOnboardingAnswers as completeOnboardingAnswersAction,
} from '../Welcome/actions'
import {
  destroyOnboardingAnswer as destroyOnboardingAnswerAction,
  postOnboardingAnswer as postOnboardingAnswerAction,
  requestOnboardingQuestion as requestOnboardingQuestionAction,
} from './actions'
import defaultSettingsByQuestion, { isFinalStep } from './buildSettings'
import OnboardingEvents from '../../amplitude-events/Onboarding.amplitude'

const mapStateToProps = ({
  account: {
    company,
    user,
  },
  onboarding: {
    error,
    lastAnswerSubmited,
    loading,
    question,
  },
  welcome: {
    initialStep,
    isOnboardingComplete,
    onboardingAnswers,
  },
}) => ({
  companyType: prop('type', company),
  error,
  initialStep,
  isOnboardingComplete,
  lastAnswerSubmited,
  loading,
  onboardingAnswers,
  question,
  userId: prop('id', user),
})

const mapDispatchToProps = {
  completeOnboardingAnswers: completeOnboardingAnswersAction,
  destroyOnboardingAnswer: destroyOnboardingAnswerAction,
  postOnboardingAnswer: postOnboardingAnswerAction,
  requestOnboardingQuestion: requestOnboardingQuestionAction,
}

const enhanced = compose(
  translate(),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)

const Onboarding = ({
  companyType,
  completeOnboardingAnswers,
  destroyOnboardingAnswer,
  error,
  initialStep,
  isOnboardingComplete,
  lastAnswerSubmited,
  loading,
  onSubmitOnboarding,
  postOnboardingAnswer,
  question,
  requestOnboardingQuestion,
  t,
  userId,
}) => {
  const [onboardingStarted, setOnboardingStarted] = useState(initialStep > 0)
  const [currentQuestion, setCurrentQuestion] = useState(initialStep)
  const [platformQuestion, setPlatformQuestion] = useState({
    options: [],
    title: 'Qual é a plataforma do seu site?',
  })

  const history = useHistory()

  const {
    deadEnd,
    label,
    questionId,
  } = defaultSettingsByQuestion[currentQuestion]

  const questionOptions = label === 'platform' ? platformQuestion : question

  const finalStep = isFinalStep(currentQuestion)

  const progressPercent = (
    Math.ceil(currentQuestion / defaultSettingsByQuestion.length * 100)
  )

  const handleStatus = currentQuestion === finalStep ? 'finishing' : 'boarding'

  const nextQuestion = () => {
    if (!finalStep) {
      setCurrentQuestion(currentQuestion + 1)
    }
  }

  const previousQuestion = () => {
    const handlePreviousQuestion = {
      default: () => {
        destroyOnboardingAnswer(questionId)
        setCurrentQuestion(currentQuestion - 1)
      },
      integrationType: () => {
        destroyOnboardingAnswer(questionId)
        setOnboardingStarted(false)
      },
    }

    return (handlePreviousQuestion[label] || handlePreviousQuestion.default)()
  }

  const handleStartOnboarding = () => {
    setOnboardingStarted(true)
    OnboardingEvents.events.actions.answerQuestionsButtonClicked({ answerLabel: 'responder pesquisa', questionLabelKey: 'welcome-page' })
  }

  const onSubmit = (answer) => {
    const getAnswerPayload = {
      answer,
      lastAnswer: finalStep,
      question_id: questionId,
      user_id: /^user_*/.test(userId) ? null : userId,
    }

    if (!deadEnd && getAnswerPayload.answer) {
      postOnboardingAnswer(getAnswerPayload)
    }

    nextQuestion()
  }

  const handleKycRedirect = () => {
    history.push('/kyc-conclusion')
  }

  useEffect(() => {
    if (!onboardingStarted) {
      OnboardingEvents.events.view.loadView('welcome-page')
      return
    }
    if (onboardingStarted) {
      if (label === 'platform') {
        const getPlatforms = async () => {
          try {
            const allPlataforms = await getPagarmePlatforms()

            setPlatformQuestion(
              (prevState => ({ ...prevState, options: [...allPlataforms] }))
            )
          } catch (err) {
            console.error(err)
          }
        }

        getPlatforms()
      } else {
        requestOnboardingQuestion(questionId)
      }
    }
  }, [
    onboardingStarted,
    questionId,
    requestOnboardingQuestion,
    deadEnd,
    label,
  ])

  useEffect(() => {
    if (question && companyType !== 'self_register') {
      onSubmit('NOT_SELF_REGISTER')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question, companyType])

  if (isOnboardingComplete) {
    return <Redirect to="/home" />
  }

  if (lastAnswerSubmited) {
    return (
      <FakeLoader
        runAfterLoader={() => {
          completeOnboardingAnswers()
          onSubmitOnboarding()
          handleKycRedirect()
        }}
        t={t}
      />
    )
  }

  return (
    <>
      <OnboardingContainer
        handleStartOnboarding={handleStartOnboarding}
        loading={loading}
        error={!!error}
        onboardingStarted={onboardingStarted}
        onReturn={previousQuestion}
        onSubmit={onSubmit}
        progressPercent={progressPercent}
        question={questionOptions}
        questionSettings={defaultSettingsByQuestion[currentQuestion]}
        status={handleStatus}
        t={t}
      />
    </>
  )
}

Onboarding.propTypes = {
  companyType: PropTypes.string,
  completeOnboardingAnswers: PropTypes.func.isRequired,
  destroyOnboardingAnswer: PropTypes.func.isRequired,
  error: PropTypes.shape({}),
  initialStep: PropTypes.number.isRequired,
  isOnboardingComplete: PropTypes.bool.isRequired,
  lastAnswerSubmited: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onboardingAnswers: PropTypes.shape({}),
  onSubmitOnboarding: PropTypes.func,
  postOnboardingAnswer: PropTypes.func.isRequired,
  question: PropTypes.shape({}),
  requestOnboardingQuestion: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  userId: PropTypes.string,
}

Onboarding.defaultProps = {
  companyType: '',
  error: {},
  onboardingAnswers: {},
  onSubmitOnboarding: () => { },
  question: null,
  userId: '',
}

export default enhanced(Onboarding)
